import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { countrySchema } from "../../../schemas";
import Table from "../../../components/tables/table";
// import { useDispatch } from "react-redux";
import { country_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Api from "../../../ApiServices/Api";

const API = process.env.REACT_APP_API_URL;

function RoleList() {
	const pages = [{ title: "Country List", href: "/country", module_id:23 }];
	const [countryList, setCountryList] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [country_id, setCountryId] = useState("");
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		country_id: null,
	});
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Korvato  • Country";
		getAllCountries()
	}, []);

	const getAllCountries = async () => {
		try {
			setIsLoading(true)
			const { data } = await Api().get('/api/dashboard/masters/country');
			console.log("All Countries Data", data)
			if (data.status) {
				setCountryList(data.data);
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error);
			setIsLoading(false)
		}
	};

	const addCountry = async () => {
		try {
			setIsLoading(true)
			console.log("Add Country")
			let body = {
				country_name: values.country_name,
				country_code: values.country_code,
				country_isd_code: values.country_isd_code
			};
			const res = await Api().post(`/api/dashboard/masters/country/save`, country_id !== undefined || country_id !== null || country_id !== "" ? { ...body, country_id } : body);
			if (res.status) {
				getAllCountries()
			}
			console.log("Add Country Response", res)
			setIsLoading(false)
		} catch (error) {
			console.log(error);
			setIsLoading(false)
		}
	};

	const initialValues = {
		country_id: "",
		country_name: "",
		country_code: "",
		country_isd_code: "",
		country_is_active: ""
	};

	const [formCountry, setFormCountry] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setCountryId(id);
			const initialValues = {
				country_id: obj.country_id,
				country_name: obj.country_name,
				country_code: obj.country_code,
				country_isd_code: obj.country_isd_code,
				country_is_active: obj.country_is_active,
			};
			setFormCountry(initialValues);
		} else {
			setCountryId("");
			setFormCountry(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const onDeleteOpen = (country_id) => {
		setConfirmationModal({ country_id, status: true });
	};

	const onDeleteRole = (country_id) => {
		setConfirmationModal({ country_id: null, status: false });
	};
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formCountry,
			validationSchema: countrySchema,
			onSubmit: (values, action) => {
				console.log("Submit Clicked", values);

				addCountry()
				console.log("Update Role")
				action.resetForm();
				if (modalOpenFlage === true) {
					getAllCountries()
					setmodalOpenFlage(false);
				}
				navigate("/country");
			},
		});
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteRole(confirmationModal.country_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Countries</h1>
				<p className="mt-2 text-sm text-gray-700">A list of all the Countries.</p>
			</div>
			<div className="mt-4 flex">
				<Link
					onClick={() => handleDrawer("add", "", {})}
					type="button"
					className="inline-flex items-center justify-center rounded-md bbt bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
				>
					Add Country
				</Link>
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={country_columns({ onDeleteOpen, handleDrawer })}
					data={countryList?.length > 0 && !isLoading ? countryList : []}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-cyan-700 py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{country_id ? "Update" : "Add"} Country
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="p-4 sm:p-6">
															<div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
																<div>
																	<label
																		htmlFor="country_name"
																		className="block text-sm font-medium text-gray-900"
																	>
																		Country Name
																	</label>
																</div>
																<div className="mt-1 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.country_name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Country Name"
																		name="country_name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.country_name &&
																		touched.country_name ? (
																		<p className="text-red-600 text-sm">
																			{errors.country_name}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
																<div>
																	<label
																		htmlFor="country_code"
																		className="block text-sm font-medium text-gray-900"
																	>
																		Country Code
																	</label>
																</div>
																<div className="mt-1 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.country_code}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Country Code"
																		name="country_code"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.country_code &&
																		touched.country_code ? (
																		<p className="text-red-600 text-sm">
																			{errors.country_code}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
																<div>
																	<label
																		htmlFor="country_isd_code"
																		className="block text-sm font-medium text-gray-900"
																	>
																		Country Calling Code
																	</label>
																</div>
																<div className="mt-1 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.country_isd_code}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="number"
																		placeholder="Enter Country Calling Code"
																		name="country_isd_code"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.country_isd_code &&
																		touched.country_isd_code ? (
																		<p className="text-red-600 text-sm">
																			{errors.country_isd_code}
																		</p>
																	) : null}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button
														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{country_id ? "Update" : "Add"}
													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default RoleList;

import {
  GET_ALL_COUNTRY_BEGIN,
  GET_ALL_COUNTRY_SUCCESS,
  GET_ALL_COUNTRY_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const CountryReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_COUNTRY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_COUNTRY_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_COUNTRY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };

    default:
      return state;
  }
};

export default CountryReducer;

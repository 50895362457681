export const GET_ALL_ROLE_BEGIN = "GET_ALL_ROLE_BEGIN"
export const GET_ALL_ROLE_SUCCESS = "GET_ALL_ROLE_SUCCESS"
export const GET_ALL_ROLE_ERROR = "GET_ALL_ROLE_ERROR"

export const ADD_ROLE_BEGIN = "ADD_ROLE_BEGIN"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR"

export const GET_ROLE_BEGIN = "GET_ROLE_BEGIN"
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS"
export const GET_ROLE_ERROR = "GET_ROLE_ERROR"

export const UPDATE_ROLE_BEGIN = "UPDATE_ROLE_BEGIN"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR"

export const DELETE_ROLE_BEGIN = "DELETE_ROLE_BEGIN"
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS"
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR"
import momentTz from 'moment-timezone';
import { read, utils, writeFile } from 'xlsx';
import { timeZone } from './constant';
import moment from 'moment';
export const exportToExcel = (data, headings, fileName, sheetName) => {
  // Create a new workbook
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet([]);
  utils.sheet_add_aoa(worksheet, headings);
  utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
  utils.book_append_sheet(workbook, worksheet, 'Report');
  writeFile(workbook, fileName);
};

export const calculateAge = (dateOfBirth) => {
  // console.log("dateOfBirth=>", dateOfBirth)
  const dob = new Date(dateOfBirth);
  const now = new Date();

  const diff = now.getTime() - dob.getTime();
  // console.log("diff=>", diff)
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  return age;
}

// Function to remove HTML tags using regex
export const removeTagsFromString = (htmlString) => {
  const regex = /(<([^>]+)>)/ig;
  return htmlString.replace(regex, '');
};

export const convertToAnotherTimezone = (date) => {
  var fmt = "MM/DD/YYYY h:mm:ss A";  // must match the input
  // console.log(momentTz(date).format(fmt), "India")
  var zone = timeZone;
  var m = momentTz.tz(date, zone).format(fmt);
  // console.log(m, zone)
  return m
}


export const convertUTStoIST = (date) => {
  var fmt = "MM/DD/YYYY h:mm:ss A";  // must match the input
  // console.log(momentTz(date).format(fmt), "India")
  const parsedDate = moment(date);
  // console.log(parsedDate)
  var local = date;
  if (parsedDate.isValid()) {
    const utcMoment = momentTz.utc(parsedDate);
    // Convert UTC to IST (Indian Standard Time)
    local = utcMoment.add(5, 'hours').add(30, 'minutes');
  }
  return local
}

// Helper function to get allowed actions based on pathname
export const getAllowedActions = (allModules, pathname) => {
  let permissionArr = [];
  if (allModules?.length > 0) {
    allModules.map((module) => {
      if (module.sys_module_relation.module_key === pathname) {
        permissionArr.push(module);
      }
    })
  }
  return permissionArr;
};

export const checkPermissionsAndRedirect = (pathname, allModules, navigate) => {

  let moduleArr = [];
  if (allModules?.length > 0) {
    allModules.map((module) => {
      if (module.sys_module_relation.module_key === pathname) {
        moduleArr.push(module.sys_module_relation.module_key);
      }
    })
  }
  // If moduleArr is empty, redirect to '/'
  if (moduleArr.length == 0) {
    navigate('/');
    // window.location.redirect="/";
    return;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

import React from 'react';

const ConnectedAccLoader = ({cout}) => {

  let items = cout ? cout : 3;

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-4 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
    <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
      {
        Array(items).fill(1).map(() => {
        //data.map((data, index) => {
          return (
            <div class="border border-slate-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse ">
              <div class="flex space-x-4">
                <div class="rounded-full bg-slate-200 h-12 w-12"></div>
                <div class="flex-1 space-y-6 py-1">
                  <div class="h-2 bg-slate-200 rounded"></div>
                  <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                      <div class="h-3 bg-slate-200 rounded col-span-2"></div>
                      <div class="h-3 bg-slate-200 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div class="h-4 bg-slate-200 rounded mb-2"></div>
                <div class="h-4 bg-slate-200 rounded mb-2"></div>
                <div class="h-4 bg-slate-200 rounded"></div>
              </div>
              </div>
            </div>
          )
        })
      }
      
    </ul>
    </div>
    </div>
  );
};

export default ConnectedAccLoader;

import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { stateSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { state_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Api from "../../../ApiServices/Api";

const API = process.env.REACT_APP_API_URL;

function StateList() {
    const pages = [{ title: "State List", href: "/state", module_id: 24 }];
    const [stateList, setStateList] = useState(false)
    const [countryList, setCountryList] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [modalOpenFlage, setmodalOpenFlage] = useState(false);
    const [state_id, setStateid] = useState("");
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        state_id: null,
    });
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Korvato  • States";
        getAllCountries()
        getAllStates()
    }, []);

    const getAllCountries = async () => {
        try {
            setIsLoading(true)
            const { data } = await Api().get(`/api/dashboard/masters/country`);
            console.log("All Countries Data", data)
            if (data.status) {
                setCountryList(data.data);
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    const getAllStates = async () => {
        try {
            setIsLoading(true)
            const { data } = await Api().get(`/api/dashboard/masters/state`);
            // console.log("All States Data", data)
            if (data.status) {
                setStateList(data.data);
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    const addState = async () => {
        try {
            setIsLoading(true)
            // console.log("Add State")
            let body = {
                state_name_en: values.state_name_en,
                state_name_ar: values.state_name_ar,
                fk_country_id: +values.fk_country_id
            };
            const res = await Api().post(`/api/dashboard/masters/state`, state_id !== undefined || state_id !== null || state_id !== "" ? { ...body, state_id } : body);
            if (res.status) {
                getAllStates()
            }
            // console.log("Add State Response", res)
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    const initialValues = {
        state_id: "",
        state_name_en: "",
        state_name_ar: "",
    };

    const [formState, setFormState] = useState(initialValues);

    const handleDrawer = (type, id, obj) => {
        if ((type === "edit", id)) {
            setStateid(id);
            const initialValues = {
                state_id: obj.state_id,
                state_name_en: obj.state_name_en,
                state_name_ar: obj.state_name_ar,
                fk_country_id: obj.m_country_relation.country_id
            };
            setFormState(initialValues);
        } else {
            setStateid("");
            setFormState(initialValues);
        }
        if (modalOpenFlage === false) {
            setmodalOpenFlage(true);
        }
    };
    const onDeleteOpen = (state_id) => {
        setConfirmationModal({ state_id, status: true });
    };

    const onDeleteState = (state_id) => {
        setConfirmationModal({ state_id: null, status: false });
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: formState,
            validationSchema: stateSchema,
            onSubmit: (values, action) => {
                // console.log("Submit Clicked", values);
                addState()
                // console.log("Update State")
                action.resetForm();
                if (modalOpenFlage === true) {
                    getAllStates()
                    setmodalOpenFlage(false);
                }
                navigate("/state");
            },
        });
    return (
        <div className="px-4 sm:px-4 lg:px-4">
            <ConfirmationModal
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteState(confirmationModal.state_id)}
            />
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">State</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the States.</p>
            </div>
            <div className="mt-4 flex">
                <Link
                    onClick={() => handleDrawer("add", "", {})}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md bbt bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                >
                    Add State
                </Link>
            </div>

            {isLoading ? (
                <FallingLinesLoader />
            ) : (
                <Table
                    columns={state_columns({ onDeleteOpen, handleDrawer })}
                    data={stateList?.length > 0 && !isLoading ? stateList : []}
                    is_toggle={false}
                />
            )}

            {isLoading ? null :
                (<div>
                    <Transition.Root show={modalOpenFlage} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-10"
                            onClose={setmodalOpenFlage}
                        >
                            <div className="fixed inset-0" />
                            <div className="fixed inset-0 overflow-hidden">
                                <div className="absolute inset-0 overflow-hidden">
                                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                                            enterFrom="translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                                            leaveFrom="translate-x-0"
                                            leaveTo="translate-x-full"
                                        >
                                            <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        handleSubmit(e);
                                                    }}
                                                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                >
                                                    <div className="h-0 flex-1 overflow-y-auto">
                                                        <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                                                            <div className="flex items-center justify-between">
                                                                <Dialog.Title className="text-lg font-medium text-white">
                                                                    {state_id ? "Update" : "Add"} State
                                                                </Dialog.Title>
                                                                <div className="ml-3 flex h-7 items-center">
                                                                    <button
                                                                        type="button"
                                                                        className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                                                        onClick={() => setmodalOpenFlage(false)}
                                                                    >
                                                                        <span className="sr-only">Close panel</span>
                                                                        <XMarkIcon
                                                                            className="h-6 w-6"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-1 flex-col justify-between">
                                                            <div className="p-4 sm:p-6">
                                                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="state_name_en"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            State Name English
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.state_name_en}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter State Name En"
                                                                            name="state_name_en"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.state_name_en &&
                                                                            touched.state_name_en ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.state_name_en}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="state_name_en"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            State Name Arabic
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.state_name_ar}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter State Name Ar"
                                                                            name="state_name_ar"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.state_name_ar &&
                                                                            touched.state_name_ar ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.state_name_ar}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                {
                                                                    countryList?.length > 0 ? (
                                                                        <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                                                    <label
                                                                        htmlFor="fk_country_id"
                                                                        className="block text-sm font-medium text-gray-900"
                                                                    >
                                                                        Country
                                                                    </label>
                                                                    <select
                                                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        name="fk_country_id"
                                                                        id="fk_country_id"
                                                                        value={values.fk_country_id}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                        <option value={null}> Select Country </option>
                                                                        {countryList?.map((country, i) => (
                                                                            <option
                                                                                defaultValue={state_id ? values.fk_country_id : ""
                                                                                }
                                                                                // selected={
                                                                                //     country.country_id === values.fk_country_id
                                                                                //         ? "selected"
                                                                                //         : ""}
                                                                                key={country.country_id}
                                                                                value={country.country_id}
                                                                            >
                                                                                {country.country_name}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    {errors.fk_country_id && touched.fk_country_id ? (
                                                                        <div className="text-sm text-red-600">
                                                                            {errors.fk_country_id}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                    ) : null
                                                                }
                                                                

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                        <button
                                                            type="button"
                                                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                            onClick={() => {
                                                                setmodalOpenFlage(false);
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="ml-4 inline-flex justify-center rounded-md bbt bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                        >
                                                            {state_id ? "Update" : "Add"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </div>)
            }
        </div>
    );
}
export default StateList;

export const LOGIN_BEGIN = "LOGIN_BEGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const LOGOUT_BEGIN = "LOGOUT_BEGIN"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_ERROR = "LOGOUT_ERROR"

export const GET_ALL_USERS_BEGIN = "GET_ALL_USERS_BEGIN"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR"

export const ADD_USER_BEGIN = "ADD_USER_BEGIN"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_ERROR = "ADD_USER_ERROR"

export const GET_USER_BEGIN = "GET_USER_BEGIN"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_ERROR = "GET_USER_ERROR"

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"
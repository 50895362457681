import React, { useEffect, useState } from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button, PageButton } from "./paginationButtons";
import { SortIcon, SortUpIcon, SortDownIcon } from "../../assets/icons/sorting";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { FaAngleDown, FaAngleUp, FaJedi } from 'react-icons/fa';

// Define a default UI for filtering

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  // console.log(preGlobalFilteredRows, "onFilterChange >>>>>")
  const [value, setValue] = React.useState(globalFilter);
  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  // console.log(value, 'value')
  return (
    <label className="flex gap-x-2 items-baseline">
      {/* <span className="text-gray-700">Search: </span> */}
      <input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-[#0172fd] focus:ring focus:ring-[#11ffdb] focus:ring-opacity-10"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onFilterChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

function Table({
  columns,
  data,
  is_toggle,
  table_id,
  printRef,
  zoom,
  initialData,
  isCustomPagination,
  fetchData,
  pageCount: controlledPageCount,
  customPageNo,
  setCustomPageNo,
}) {
  const tableOptions = {
    columns,
    data,
    is_toggle,
    manualPagination: isCustomPagination,
    // initialState: isCustomPagination ? { pageIndex: 1, pageSize: 10 } : '',
    customPageNo,
    setCustomPageNo,
  };

  if (isCustomPagination) {
    tableOptions.pageCount = controlledPageCount;
    tableOptions.initialState= { pageIndex: customPageNo };
  }
  console.log("tableOptions.pageCount=>",tableOptions.pageCount,customPageNo)
  const {
    state,
    pageOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
  } = useTable(
    tableOptions,
    // {
    //   columns,
    //   data,
    //   is_toggle,
    //   manualPagination: isCustomPagination,
    //   // pageCount: isCustomPagination ? controlledPageCount : undefined,
    //   initialState: isCustomPagination?{ pageIndex: 1, pageSize: 10 }:'', // Initial state example
    //   customPageNo,
    //   setCustomPageNo,
    //   // initialState: { pageIndex: 1 },
    // },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );
  useEffect(() => {
    if (isCustomPagination) {
      fetchData({ pageIndex: state.pageIndex });
      state.pageIndex=customPageNo
    }
  }, [isCustomPagination, customPageNo]);
  useEffect(() => {
    setPageSize(50);
  }, []);
  const [openRow, setOpenRow] = useState({}); // State to track open rows
  const toggleRow = (rowIndex) => {
    setOpenRow(prevOpenRow => ({
      ...prevOpenRow,
      [rowIndex]: !prevOpenRow[rowIndex]
    }));
  };

  const navigate = useNavigate()
  const [allData, setAllData] = useState(data);


  return (
    <>
      {/* SEARCH UI */}
      <div className="sm:flex sm:gap-x-3 mt-5 justify-between">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}

      </div>
      {/* TABLE UI */}
      <div
        id={table_id}
        ref={printRef}
        className={`mt-4 flex flex-col ${zoom ? zoom : ""}`}
      >
        <div className="my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {/* <th>Sr No.</th> */}
                      {is_toggle ? (<th
                        scope="col"
                        className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"

                      ></th>) : (null)} {/* Additional header for toggle column */}

                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          {...column.getHeaderProps({
                            style: { width: column.width },
                          })}
                        >
                          <div className="flex items-center justify-between">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}

                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page?.length > 0 ? (
                    page.map((row, i) => {
                      // new

                      prepareRow(row);
                      return (
                        <>
                          <tr {...row.getRowProps()}>
                            {(is_toggle) ? (<td
                              className={`px-6 py-4 whitespace-nowrap text-left `}
                              role="cell">
                              {(allData[i]?.DateArrDropCount?.length > 0) ? (<button onClick={() => toggleRow(i)}>
                                {openRow[i] ? <FaAngleUp /> : <FaAngleDown />}
                              </button>) : (<></>)}
                            </td>) : (<></>)}
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-6 py-4 whitespace-nowrap text-left `}
                                  role="cell"
                                >
                                  {cell.column.Cell.name === "defaultRenderer" ? (
                                    <div className="text-sm text-gray-500">
                                      {cell.render("Cell")}
                                    </div>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>

                              );
                            })}
                          </tr>

                          {openRow[i] && (
                            <tr>
                              <td colSpan={row.cells.length + 1}>
                              </td>
                            </tr >
                          )}
                        </>
                      );
                    })
                  ) : (
                    // <div className="p-5 text-center whitespace-nowrap w-full text-gray-500">
                    <>{headerGroups.map((headerGroup) => (
                      <tr>
                        <td className='p-5 text-center whitespace-nowrap w-full text-gray-500' colSpan={headerGroup.headers.length}>No Data Found</td>
                      </tr>
                    ))}</>

                    // </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div >


      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
          <div className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 pl-2 font-medium">
            {preGlobalFilteredRows.length < 50 ? (
              <span>
                Data{" "}
                <strong>
                  {preGlobalFilteredRows.length} of{" "}
                  {preGlobalFilteredRows.length}
                </strong>{" "}
              </span>
            ) : (
              <span>
                Data{" "}
                <strong>
                  {state.pageSize} of {preGlobalFilteredRows.length}
                </strong>{" "}
              </span>
            )}
          </div>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {pageOptions?.map((page, key) =>
            page < Math.ceil(pageOptions.length / 2) + 1 &&
              page + 1 < state.pageIndex + 3 &&
              page + 2 > state.pageIndex ? (
              // || ((page + 1) < 4 && state.pageIndex < 3)
              <button
                key={key}
                onClick={(e) => {
                  if (isCustomPagination) {
                    setCustomPageNo(page);
                  }
                  gotoPage(page);
                }}
                // className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                className={`${page == state.pageIndex
                  ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                  : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}
              >
                {page + 1}
              </button>
            ) : null
          )}

          {/* Done code */}
          {pageOptions?.map((page, key) =>
            state.pageIndex > Math.ceil(pageOptions.length / 2) + 1 &&
              (page == 0 || page == 1 || page == 2) ? (
              <button
                key={key}
                onClick={(e) => {
                  if (isCustomPagination) {
                    setCustomPageNo(page);
                  }
                  gotoPage(page);
                }}
                className={`${page == state.pageIndex
                  ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                  : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}
              >
                {page + 1}
              </button>
            ) : null
          )}

          {pageOptions.length > 3 ? (
            <div>
              <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                ...
              </span>

              {pageOptions?.map((page, key) =>
                state.pageIndex <= Math.ceil(pageOptions.length / 2) - 1 &&
                  (page == pageOptions.length - 3 ||
                    page == pageOptions.length - 2 ||
                    page == pageOptions.length - 1) ? (
                  <button
                    key={key}
                    onClick={(e) => {
                      if (isCustomPagination) {
                        setCustomPageNo(page);
                      }
                      gotoPage(page);
                    }}
                    className={`${page == state.pageIndex
                      ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                      : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      }`}
                  >
                    {page + 1}
                  </button>
                ) : null
              )}

              {pageOptions?.map((page, key) =>
                page > Math.ceil(pageOptions.length / 2) &&
                  page + 1 < state.pageIndex + 3 &&
                  page + 2 > state.pageIndex ? (
                  // || ((page + 1) < 4 && state.pageIndex < 3)
                  <button
                    key={key}
                    onClick={(e) => {
                      if (isCustomPagination) {
                        setCustomPageNo(page);
                      }
                      gotoPage(page);
                    }}
                    // className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    className={`${page == state.pageIndex
                      ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                      : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      }`}
                  >
                    {page + 1}
                  </button>
                ) : null
              )}
            </div>
          ) : null}

        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>
    </>
  );
}

export default Table;

/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
import { toast } from "react-toast";
export default {

  async SaveResetPassword(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/user/reset-password`,payload);
    return response;
  },
  async getUserProfile(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/user/${payload}`);
    return response;
  },

  async getAllUsers(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(
      `api/dashboard/user/?search=${payload}`
    );
    return response;
  },

  async UserExcelExport(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/user/user-excell`,
      payload
    );
    return response;
  },

  async userExcelImport(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/user/user-import`,
      payload
    );
    return response;
  },

  async updateUserInfo(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/user/update-user-info`,
      payload
    );
    return response;
  },

  async saveMUser(payload) {
    // await AuthToken.getCurrentAuth();
    try {

      const res = await Api().post("api/dashboard/user/", payload);
      // console.log("Register Response", res.data, body)
      if (res && res.data.status) {
        toast.success(res.data.message);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("Error in Register Api ", error);
      return null;
    }
  },

  async getAllMUsersList() {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/user`);
    return response;
  },

  async getRoleDetails(role_id) {
    // await AuthToken.getCurrentAuth();
    const res = await Api().get(`api/dashboard/masters/role/${role_id}`);
    return res;
  },

  // Qc Test Lots API Ends
  async getPermissionsByRoleID(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/masters/role/${payload}`);
    return response;
  },

  async getAllPermissions() {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/masters/permission/list`);
    return response;
  },

  async saveRole(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/masters/role/auto-save`, //old wala save th end point
      payload
    );
    return response;
  },

  async removeMaster(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/masters/master/remove/`,
      payload
    );
    return response;
  },

  async getAllMatrics(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-matrics/${payload}`);
    return response;
  },

  async getTradeHistory(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-matrics/trade-history/${payload}`);
    return response;
  },

  async banCustomerAndAccs(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-users/ban`, payload);
    return response;
  },

  async getAILog(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-matrics/trade/ai-trade-log/${payload}`);
    return response;
  },

};

import React from 'react';
import { FaSpinner } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ActionMenu from './PCActionMenu';
import LoadingButton from "../../../../components/LoadingButton";
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/20/solid';

const GroupCard = ({ index, deleteIndex, group, OnChangeAccountType, onAddAccount, handleClickMenu, handleEditGroup, handleRemoveGroup,handleRemoveGroupAcc }) => {

  const navigate = useNavigate();

  return (
    <div className="bg-white shadow-md rounded-lg p-2 mb-6">

      <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none pb-3">
        <div className="flex items-center gap-x-6">
          <img
            src="config.png"
            alt=""
            className="h-12 w-12 flex-none rounded-full ring-1 ring-gray-900/10"
          />
          <h1>
            <div className="mt-1 text-base font-semibold leading-6 text-gray-900">{group.pgrp_name}</div>
          </h1>
        </div>
        <div className="flex items-center gap-x-4 sm:gap-x-6">
          <a
            href="#"
            className="rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 "
            onClick={() => {
              handleEditGroup(group)
            }}
          >
            Edit
          </a>

          {
            deleteIndex === index ? (<LoadingButton title=" Removing" />) : (
              <a
                href="#"
                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 "
                onClick={() => {
                  handleRemoveGroup(group, index);
                }}
              >
                Delete
              </a>
            )
          }


        </div>
      </div>

      {
        group?.m_param_group_map?.length > 0 ? (
          <div>
            <table className="min-w-full bg-white box-border border rounded-md">
              <thead>
                <tr>
                  <th className="py-1 px-2 font-light bg-gray-200">Account</th>
                  <th className="py-1 px-2 font-light bg-gray-200"></th>
                </tr>
              </thead>
              <tbody>
                {group?.m_param_group_map?.length > 0 && group?.m_param_group_map?.map((account) => (
                  <tr key={account.pgam_id}>
                    <td className="py-2 px-4 border-b text-sm font-light text-center text-blue-500 cursor-pointer" >
                      <span 
                      onClick={() =>{
                        console.log({account});
                        // navigate("/accm?uid=" + account?.user_id + "&name=" + account?.username + "&accid=" + account?.acc_id);
                      }}
                      >
                        {account?.pgam_acc_no}
                      </span>
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {/* <ActionMenu handleClick={handleClickMenu} details={account} /> */}
                      
                        <TrashIcon
                          className="text-center cursor-pointer h-5 w-5 text-red-600" aria-hidden="true" 
                          onClick={() => {
                            handleClickMenu({type: 1, data: account});
                          }}
                         />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              type="button"
              className="mt-2 inline-flex w-full justify-center rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => onAddAccount(group)}
            >
              + Add Account
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={() => {
                onAddAccount(group);
              }}
              type="button"
              className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400"
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                />
              </svg>
              <span className="mt-2 block text-sm font-semibold text-gray-900">
                Link Account
              </span>
            </button>
          </div>
        )
      }
    </div>
  );
};

export default GroupCard;

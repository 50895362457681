export const GET_ALL_USER_BEGIN = "GET_ALL_USER_BEGIN"
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS"
export const GET_ALL_USER_ERROR = "GET_ALL_USER_ERROR"

export const ADD_USER_BEGIN = "ADD_USER_BEGIN"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_ERROR = "ADD_USER_ERROR"

export const GET_USER_BEGIN = "GET_USER_BEGIN"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_ERROR = "GET_USER_ERROR"

export const GET_USER_INFO_BEGIN = "GET_USER_INFO_BEGIN"
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS"
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR"

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

export const DELETE_USER_BEGIN = "DELETE_USER_BEGIN"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"
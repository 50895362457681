import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import { plan_columns } from "../../../../components/tables/tableheader";
import { planSchema } from "../../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../../ApiServices/MasterServices";
import WhopServices from "../../../../ApiServices/WhopServices";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../../helper/commonHelper";

function PlanList() {
  const pages = [{ title: "Plan List", href: "/plan", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [wp_plan_id, setPlanid] = useState("");
  const [planList, setPlanList] = useState([]);
  const [banner, setBanner] = useState(null);
  const [tempBanner, setTempBanner] = useState(null);
  const [base64banner, setBase64banner] = useState(null);
  const [imageUrl, setImageUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    wp_plan_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    wp_plan_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "Korvato •  Plans";
    getAllPlans(3);
  }, []);

  const getAllPlans = async (payload) => {
    setIsLoading(true);
    try {
      const res = await WhopServices.getAllWhopPlan(payload)
      if (res.status) {
        setPlanList(res.data.data);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const onStatusUpdate = (wp_plan_id, plan_is_active) => {
    let payload = {
      table_name: "master_plan",
      table_field: "wp_plan_id",
      table_field_val: wp_plan_id,
      table_status_field: "plan_is_active",
      table_status_val: plan_is_active ? false : true,
      table_text: "Plan",
      deleted_by_key: "plan_updated_by",
      delete_active_txt: plan_is_active ? " Disabled" : " Enabled",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllPlans(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  let initialValues = {
    wp_plan_id: "",
    plan_plan_type: "",
    plan_release_method: "",
    plan_billing_period: "",
    plan_initial_price: "",
    plan_is_active: true,
  };

  const [formPlan, setFormPlan] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    setBase64banner(null);
    setBanner(null);
    setTempBanner(null);
    if ((type === "edit", id)) {
      setPlanid(id);
      setImageUrl(obj?.plan_billing_period);
      setTempBanner(obj?.plan_billing_period);
      const initialValues = {
        wp_plan_id: obj.wp_plan_id,
        plan_plan_type: obj.plan_plan_type,
        plan_release_method: obj.plan_release_method,
        // plan_billing_period: obj.plan_billing_period,
        plan_billing_period: base64banner ? base64banner : null,
        plan_initial_price: obj.plan_initial_price,
        plan_is_active: obj.plan_is_active,
      };

      setFormPlan(initialValues);
    } else {
      setPlanid("");
      setFormPlan(initialValues);
      setBase64banner(null);
      setBanner(null);
      setTempBanner(null);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (wp_plan_id, plan_is_active) => {
    setConfirmationModal({ wp_plan_id, plan_is_active, status: true });
  };

  const onDeleteOpenSection = (wp_plan_id, plan_is_deleted) => {
    setRemoveConfirmationModal({ wp_plan_id, plan_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formPlan,
      validationSchema: planSchema,
      onSubmit: async (values, action) => {

        let body = {
          wp_plan_id: wp_plan_id,
          plan_plan_type: values.plan_plan_type,
          plan_release_method: values.plan_release_method,
          plan_billing_period: values.plan_billing_period,
          plan_initial_price: values.plan_initial_price,
          plan_is_active: true,
        };

        if (body.plan_plan_type != undefined || body.plan_plan_type != null || body.plan_plan_type != "") {
          setPlanList([]);
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          let { data, status, message } = await WhopServices.SaveWhopPlan(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllPlans(3);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
              setBanner(null);
              setTempBanner(null);
              setBase64banner(null);
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllPlans(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });
  const onFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setBanner(file);
      setTempBanner(e.target.result);
      setBase64banner(e.target.result);
    };
    reader.readAsDataURL(file);
  };
  const onDeletePlan = async (wp_plan_id) => {
    let { data, status } = await WhopServices.deleteWhopPlan({ wp_plan_id })
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllPlans(3);
      }
    } else {

      toast.error(data.message);
    }
    setRemoveConfirmationModal({ wp_plan_id: null, status: false });
  };

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("Plan handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {

            rows.map((obj, index) => {
              if (obj.plan_plan_type != undefined || obj.plan_initial_price != undefined) {
                const newObj = {
                  plan_plan_type: obj.plan_plan_type !== undefined ? obj.plan_plan_type ? obj.plan_plan_type.trim() : obj.plan_plan_type.trim() : '',
                  plan_release_method: obj.plan_release_method !== undefined ? obj.plan_release_method ? obj.plan_release_method.toString().trim() : obj.plan_release_method.toString().trim() : '',
                  plan_initial_price: obj.plan_initial_price !== undefined ? obj.plan_initial_price ? obj.plan_initial_price.trim() : obj.plan_initial_price.trim() : '',
                  plan_billing_period: obj.plan_billing_period !== undefined ? obj.plan_billing_period ? obj.plan_billing_period.trim() : obj.plan_billing_period.trim() : '',
                };

                newData.push(newObj);
              }

            });

            console.log("newArray: ", newData);

            if (newData.length > 0) {
              importPlan(newData);
            }

          } else {
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  const importPlan = (data) => {
    const payload = { excel_data: data }
    MasterServices.planExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Plan Imported Successfully");
          getAllPlans(3);
        } else {
          toast.error(data.message)
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
      });
  }

  const handleExport = (excel_type) => {
    if (!planList || planList.length === 0) {
      toast.error("Plan list is empty!");
      return;
    }
    if (planList?.length > 0) {
      let newSheetdata = [];
      planList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          wp_plan_id: obj?.wp_plan_id || '-',
          plan_id: obj?.plan_id || '-',
          plan_plan_type: obj?.plan_plan_type || '-',
          plan_release_method: capitalizeFirstLetter(obj?.plan_release_method) || '-',
          plan_initial_price: capitalizeFirstLetter(obj?.plan_initial_price) || '-',
          plan_created_date: moment(obj?.plan_created_date).format("DD-MM-YYYY") || '-',
          Status: obj?.plan_is_active == true ? 'Active' : 'In-Active' || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Plan_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      const headings = [['SN', 'Plan ID','Plan Unique ID',  'Plan Type', 'Plan Method', 'Plan Initial Price', 'Created Date', 'Status']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }



  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.plan_is_active ? "Disable " : "Enable") +
          " this Plan?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.wp_plan_id,
            confirmationModal.plan_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.plan_is_deleted
            ? " Undo"
            : "Delete") +
          " this Plan?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeletePlan(removeConfirmationModal.wp_plan_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Plan List</h1>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 60)?.length >
          0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
          >
            Add Plan
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 65)?.length > 0 && planList?.length > 0 ? (
          <Link
            to={"#"}
            onClick={() => handleExport('xlsx')}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
          >
            Download Excel
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 66)?.length > 0 ? (
          <label htmlFor="file-upload" className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-3 py-1 text-xs font-medium text-white shadow-sm hffff  ml-2 cursor-pointer transition duration-300 ease-in-out">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
              <path fillRule="evenodd" d="M12 10a4 4 0 11-8 0 4 4 0 018 0zm2-2a6 6 0 11-12 0 6 6 0 0112 0z" clipRule="evenodd" />
              <path d="M10 2a8 8 0 00-8 8c0 4.418 3.582 8 8 8s8-3.582 8-8a8 8 0 00-8-8zM5.293 8.707a1 1 0 011.414 0L10 11.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
            </svg>
            Upload Plan
            <input id="file-upload" type="file" className="hidden" onChange={handleImport} />
          </label>
        ) : null}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={plan_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
          })}
          data={planList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {wp_plan_id ? "Update" : "Add"} Plan
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md  text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="plan_release_method"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Plan Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.plan_release_method}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Plan Name"
                                    name="plan_release_method"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.plan_release_method &&
                                    touched.plan_release_method ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.plan_release_method.replace("plan_release_method", "Plan Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="plan_initial_price"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Plan Plan Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.plan_initial_price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Plan Plan Name"
                                    name="plan_initial_price"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.plan_initial_price &&
                                    touched.plan_initial_price ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.plan_initial_price.replace("plan_initial_price", "Plan Plan Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="plan_plan_type"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Plan Email
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.plan_plan_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="email"
                                    placeholder="Plan Email"
                                    name="plan_plan_type"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={wp_plan_id ? true : false}
                                  />
                                  {errors.plan_plan_type && touched.plan_plan_type ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.plan_plan_type.replace("plan_plan_type", "Email")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mb-1">
                                    Upload Profile
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                      onFileChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    type="file"
                                    name="plan_billing_period"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.plan_billing_period &&
                                    touched.plan_billing_period ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.plan_billing_period}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-span-full mb-5">
                                {tempBanner ? (
                                  <img
                                    className="shadow-sm mt-4 w-40"
                                    src={
                                      wp_plan_id
                                        ? imageUrl + tempBanner
                                        : tempBanner
                                    }
                                    alt="preview-banner"
                                  />
                                ) : null}
                              </div>
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md bbt bg-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : "bg-cyan-700"
                              }`}
                          >
                            {wp_plan_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default PlanList;
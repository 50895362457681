import * as Yup from "yup";

export const groupSchema = Yup.object({
  grp_name: Yup.string().min(2).max(50).required("Please Enter the Group Name"),
});

export const pcGroupSchema = Yup.object({
  pgrp_name: Yup.string().min(2).max(50).required("Please Enter the PC Group Name"),
});

export const countrySchema = Yup.object({
  country_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Country Name"),
  country_code: Yup.string().required("please Enter 3 character Country code"),
  country_isd_code: Yup.number()
    .min(1)
    .max(999)
    .required("please Enter the Country Calling code"),
});
export const userInfoSchema = Yup.object({
  user_first_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Last Name"),
  user_email: Yup.string().min(2).max(100).required("please Enter the email"),
});
export const genderSchema = Yup.object({
  gender_name_en: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Engllish gender"),
  gender_name_ar: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Arabic gender"),
});
export const stateSchema = Yup.object({
  state_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English State Name"),
  state_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic State Name"),
  fk_country_id: Yup.string().required("Please select country"),
});
export const citySchema = Yup.object({
  city_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English City Name"),
  city_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic City Name"),
  fk_state_id: Yup.string().required("Please select state"),
});

export const roleSchema = Yup.object({
  role_name: Yup.string().min(2).max(50).required("Please Enter the Role Name"),
});

export const userSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Last Name"),
  user_email: Yup.string().email().required("Please Enter the Email address"),
  user_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  user_password: Yup.string().min(6).required("Please Enter the Password"),
  // conf_password: Yup.string()
  // 	.required()
  // 	.oneOf([Yup.ref("user_password"), null], "Password must match"),
  // user_location_id: Yup.string().required("Please Enter the Location ID"),
  user_role: Yup.string().required("Please Enter the Role"),
});

export const profileSchema = Yup.object({
  user_password: Yup.string().min(6).nullable().required("Please Enter the Password"),
  conf_password: Yup.string().nullable()
    .required()
    .oneOf([Yup.ref("user_password"), null], "Password must match"),
});

export const customerSchema = Yup.object({
  user_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Customer Name"),
  user_username: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Cusomer User Name"),
  user_email: Yup.string().email().required("Please Enter the Email address"),
});

export const productSchema = Yup.object({
  prod_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Product Name"),
  prod_description: Yup.string()
    .min(2)
    .max(1000)
    .required("Please Enter the Product Description"),
});

export const licenseSchema = Yup.object({
  lic_affiliate_username: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Affiliate Name"),
  lic_renewal_period_start: Yup.string().required("Please Enter the Renewal Period Start"),
  lic_renewal_period_end: Yup.string().required("Please Enter the Renewal Period End"),
  lic_created_at: Yup.string().required("Please Enter the Created At"),
  lic_expires_at: Yup.string().required("Please Enter the Expires At"),
  lic_quantity: Yup.number().required("Please Enter the Quantity"),
  fk_wp_user_id: Yup.string().required("Please Enter the User"),
  fk_wp_prod_id: Yup.string().required("Please Enter the Product"),
});

export const accountSchema = Yup.object({
  lam_account_no: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Account No"),
  fk_wp_lic_id: Yup.string().required("Please Enter the License"),
  fk_wp_user_id: Yup.string().required("Please Enter the User"),
  fk_wp_prod_id: Yup.string().required("Please Enter the Product"),
});

export const planSchema = Yup.object({
  plan_plan_type: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Plan Type"),
  plan_release_method: Yup.string()
    .min(2)
    .max(50)
    .required("Please Enter the Plan Release Method"),
  fk_wp_prod_id: Yup.string().required("Please Enter the Product"),
});
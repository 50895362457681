import { FallingLines, RotatingLines, InfinitySpin } from "react-loader-spinner";

const FallingLinesLoader = () => {
  return (
    <div className="flex justify-center items-center py-16">

      {/* <FallingLines color="#00d4f6" width="100" visible={true} /> */}

      {/* <RotatingLines
        visible={true}
        height="96"
        width="96"
        color="#00d4f6"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      /> */}

      <InfinitySpin
        visible={true}
        width="200"
        color="#00d4f6"
        ariaLabel="infinity-spin-loading"
      />

    </div>
  );
};

export { FallingLinesLoader };
